import { addDoc, collection, doc, writeBatch } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { removePropsFromList } from "../../helper/obj";
import { getCurrentDate } from "../../helper/time";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db, storage } from "../setup";
import { scope3overwrite } from "../../helper/results";
import { updateHistory } from "./updateHistory";

export async function createFormData(data, history_type="result_create", checkforfiles=true) {
  data["createdAt"] = getCurrentDate();
  if (!"result" in data) throw "Emission result not present";
  console.log("creating form data", data)
  let files = data.file;
  let userDoc = store.getState().user.doc;
  if (files && checkforfiles) {
    data.file = files.map((file) => (file === "string" ? file : file.name));
  }
  if (userDoc) {
    data.userId = userDoc?.id;
  }
  const { id } = await addDoc(collection(db, firestoreStatic.results), data);
  if (files && checkforfiles) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reference = ref(storage, "formdata/" + id + "/" + file.name);
      await uploadBytes(reference, file);
    }
  }
  await updateHistory(id, data, history_type)
  return scope3overwrite([{ ...data, id }])[0]; // just for frontend
}

export async function bulkUpload(rows) {
  removePropsFromList(rows);
  rows = rows.filter((row) => !isNaN(row.result));
  // clean
  const batch = writeBatch(db);
  const resultRef = collection(db, firestoreStatic.results);
  const userDoc = store.getState().user.doc;

  for (let i = 0; i < rows.length; i++) {
    batch.set(doc(resultRef), { ...rows[i], userId: userDoc?.id });
  }
  await batch.commit();
}
