import { Space } from "antd";
import { T1 } from "../../appconfig/texts";
import { fixDecimals } from "../../helper/wodash";
import { isUndefined, result } from "lodash";
import commaNumber from "comma-number";

export default function formatEmissions(emissions, min2) {
  try {
    if (isUndefined(emissions)) return "0.00 ";
    if (isNaN(emissions)) return <div style={{ color: "red" }}></div>;
    if (emissions < 0.01 && emissions > 0) return "<0.01";
    try {
      if (min2) return fixDecimals(emissions, 2)?.toFixed(2);
      else return fixDecimals(emissions, 2);
    } catch (err) {
      return fixDecimals(emissions, 2);
    }
  } catch (err) {
    return err.message;
  }
}

export const totalEmissions = (
  currentDataSource = [],
  justTheNumbers = false
) => {
  const sum = (type = "result") => {
    let result = 0;
    currentDataSource.forEach(
      (e) => (result += !isNaN(parseFloat(e[type])) ? parseFloat(e[type]) : 0)
    );
    return fixDecimals(result);
  };
  const result_emissions = fixDecimals(sum() + sum("tnd"));
  const wtt_emissions = fixDecimals(sum("wtt") + sum("wtt_tnd"));
  if (!parseFloat(sum("wtt"))) {
    if (justTheNumbers)
      return { total_emissions: 0, result_emissions: 0, wtt_emissions: 0 };
    return (
      <div>
        Total Emissions = <b>{result_emissions} </b>
      </div>
    );
  }
  let marketbased_result = null;
  if (parseFloat(sum("marketbased_result"))) {
    const marketbased_result = sum("marketbased_result");
    const marketbased_tnd = sum("marketbased_tnd");
    const marketbased_wtt = sum("marketbased_wtt");
    const marketbased_wtt_tnd = sum("marketbased_wtt_tnd");
    //
    const locationbased_result = sum("result");
    const locationbased_tnd = sum("tnd");
    const locationbased_wtt = sum("wtt");
    const locationbased_wtt_tnd = sum("wtt_tnd");
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="borderedb">
          <T1 pos="combined.emissionsTotal.combinedMarketEmissions"></T1>
          <b>
            {fixDecimals(
              marketbased_result +
                marketbased_tnd +
                marketbased_wtt +
                marketbased_wtt_tnd,
              2,
              1
            )}
          </b>{" "}
          = <T1 pos="combined.emissionsTotal.generationTnd"></T1>{" "}
          <b>{fixDecimals(marketbased_result + marketbased_tnd, 2, 1)} </b> +{" "}
          <T1 pos="combined.emissionsTotal.wttGenerationTnd"></T1>
          <b>{fixDecimals(marketbased_wtt + marketbased_wtt_tnd, 2, 1)}</b>
        </div>
        <div className="borderedb">
          <T1 pos="combined.emissionsTotal.combinedLocationEmissions"></T1>
          <b>
            {fixDecimals(
              locationbased_result +
                locationbased_tnd +
                locationbased_wtt +
                locationbased_wtt_tnd,
              2,
              1
            )}
          </b>{" "}
          = <T1 pos="combined.emissionsTotal.generationTnd"></T1>{" "}
          <b>{fixDecimals(locationbased_result + locationbased_tnd, 2, 1)} </b>{" "}
          + <T1 pos="combined.emissionsTotal.wttGenerationTnd"></T1>
          <b>{fixDecimals(locationbased_wtt + locationbased_wtt_tnd, 2, 1)}</b>
        </div>
      </div>
    );
  }
  if (justTheNumbers) {
    return {
      total_emissions: result_emissions + wtt_emissions,
      result_emissions,
      wtt_emissions,
    };
  }
  return (
    <Space direction="vertical">
      <div className="borderedb">
        <T1 pos="combined.emissionsTotal.combinedEmissions"></T1>
        <b>{fixDecimals(result_emissions + wtt_emissions, 2, 1)}</b> ={" "}
        <T1 pos="combined.emissionsTotal.emissions"></T1>{" "}
        <b>{fixDecimals(result_emissions, 2, 1)} </b> +{" "}
        <T1 pos="combined.emissionsTotal.wttEmissions"></T1>
        <b>{fixDecimals(wtt_emissions, 2, 1)}</b>
      </div>
    </Space>
  );
};
