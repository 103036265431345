import { Button, message, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompanyPageLink } from "../../../components/Link/Link";
import List from "../../../components/list/list";
import Loading from "../../../components/loading";
import { fetchCompanies } from "../../../firebase/company/get";
import {
  canAddCompany,
  designations,
  findSitesByCompanyId,
  getCurrentUserDesignation,
} from "../../../helper/attribute";
import { selectCompanies } from "../../../redux/features/appSlice";
import AddCompanyModal from "./add";

import { T1 } from "../../../appconfig/texts";
import SelectSearch from "../../../components/select/SearchSelect";
import {
  dateSorter,
  getColumnSearchProps,
  getDropdownFilterProps,
} from "../../../components/table/tableProps";
import { updateCompany } from "../../../firebase/company/update";
import { catcher } from "../../../firebase/util";
import { getPlanTitles } from "../../../helper/plans";
import { routePath } from "../../../static/routes.static";
import DateInput from "../../../components/input/date";
import { getCurrentDatePlusNDays } from "../../../helper/time";
import FormContext from "../../../contexts/FormContext";
import useSize from "../../../hooks/useSize";
export default function Companies() {
  const { isMobile, isExtraSmall } = useSize();

  const companies = useSelector(selectCompanies);
  const [modals, setModals] = useState({ add: false, update: false });
  const navigate = useNavigate();
  const setModal = (whichOne, status = new Date().getTime()) => {
    modals[whichOne] = status;
    setModals({ ...modals });
  };
  const handleUpdateCompanyPlan = async (id, newPlan) => {
    if (designations.super_admin !== getCurrentUserDesignation())
      return message.error("Not allowed");
    catcher(async () => {
      const obj = { plan: newPlan };
      if (newPlan === "demo") {
        obj.planExpiry = getCurrentDatePlusNDays(7);
      }
      await updateCompany(id, obj);
    }, {});
  };
  const handleUpdateCompanyPlanExpiry = async (id, newPlanExpiry) => {
    if (designations.super_admin !== getCurrentUserDesignation())
      return message.error("You are not allowed");
    await catcher(async () => {
      await updateCompany(id, { planExpiry: newPlanExpiry });
    }, {});
  };
  const handleUpdateCompanyDefaultDateTo = async (id, defaultDateTo) => {
    if (designations.super_admin !== getCurrentUserDesignation())
      return message.error("You are not allowed");
    await catcher(async () => {
      await updateCompany(id, { defaultDateTo });
    }, {});
  };
  useEffect(() => {
    if (!companies) fetchCompanies();
  }, []);
  const columns = [
    {
      title: <T1 pos="companies.table.name" />,
      key: "name",
      render: (_) => (
        <>
          <CompanyPageLink main={true} id={_.id} />
        </>
      ),
      width: 200,
      ...getColumnSearchProps("name", (_) => _.title),
    },

    {
      title: <T1 pos="companies.table.sites" />,
      key: "sites",
      render: (_) => (
        <>
          <List
            translate="no"
            arr={findSitesByCompanyId(_.id).map((e) => e.title)}
            handleClick={(companyTitle) => {
              const site = findSitesByCompanyId(_.id)?.find(
                (c) => c?.title === companyTitle
              );
              navigate(routePath.SiteDetails(site?.id));
            }}
            max={3}
          ></List>
        </>
      ),
      width: 200,
    },
    {
      title: <T1 pos="companies.table.createdAt" />,
      key: "createdAt",
      render: (_) => (
        <p style={{ fontWeight: 400 }}>
          <span>{_.createdAt}</span>
        </p>
      ),
      width: 200,
      sorter: dateSorter("createdAt"),
    },
    {
      title: <T1 pos="companies.table.startingMonth" />,
      key: "createdAt",
      render: (_) => (
        <p style={{ fontWeight: 400 }}>
          <span>{_.reporting_year_starting_month}</span>
        </p>
      ),
      width: 200,
    },
  ];
  if (designations.super_admin === getCurrentUserDesignation()) {
    columns.push({
      title: "Plan",
      key: "plan",
      render: (_) => {
        return (
          <>
            <SelectSearch
              fullWidth
              nolabel
              value={_.plan}
              options={getPlanTitles()}
              setValue={(plan) => handleUpdateCompanyPlan(_.id, plan)}
            />
          </>
        );
      },
      width: 200,
      ...getDropdownFilterProps(
        getPlanTitles().map((plan) => ({ plan })),
        "plan"
      ),
    });
    if (!isMobile) {
      columns.push({
        title: "More",
        key: "more",
        render: (_) => {
          const DateInputComponent = ({
            condition,
            info,
            title,
            value,
            setValue,
          }) => {
            if (!condition) return null;

            return (
              <div>
                <FormContext.Provider
                  value={{
                    bgless: true,
                    borderless: true,
                  }}
                >
                  <DateInput
                    info={!isMobile && info}
                    clear
                    title={title}
                    value={value}
                    setValue={setValue}
                  />
                </FormContext.Provider>
              </div>
            );
          };

          return (
            <>
              <DateInputComponent
                condition={_.plan === "demo"}
                info="If not present, there will be no expiry date."
                title="Expiry date"
                value={_.planExpiry}
                setValue={(planExpiry) =>
                  handleUpdateCompanyPlanExpiry(_.id, planExpiry)
                }
              />
              <DateInputComponent
                condition={_.plan === "ma"}
                info="If not present records will default to today's date."
                title="Default Date"
                value={_.defaultDateTo}
                setValue={(defaultDateTo) =>
                  handleUpdateCompanyDefaultDateTo(_.id, defaultDateTo)
                }
              />
            </>
          );
        },
      });
      columns.push({
        title: "ID",
        key: "id",
        render: (_) => <>{_.id}</>,
        width: 200,
      });
    }
  }
  if (!companies) return <Loading />;
  return (
    <>
      <Space>
        <Typography.Title level={isExtraSmall ? 3 : 2}>
          {<T1 pos="companies.title" />}
        </Typography.Title>
        {canAddCompany() && (
          <Button onClick={() => setModal("add")}>
            {<T1 pos="companies.addButton" />}
          </Button>
        )}
      </Space>
      {/*Table */}
      <Table
        scroll={{ y: "calc(100vh-220px)", x: 100 }}
        dataSource={companies}
        columns={columns}
        rowKey={"id"}
        pagination={true}
      />
      {/*Modals*/}
      <AddCompanyModal
        key={modals.add}
        visible={modals.add}
        setVisible={(v) => setModal("add", v)}
      />
    </>
  );
}
