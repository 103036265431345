import React, { useState } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import moment from "moment";
import useSize from "../../hooks/useSize";

export default function DownloadDomImage({
  elementId,
  fileName = "image.png",
  text = "captured on " + moment().format("DD-MM-YYYY"),
  makeAbsolute,
}) {
  const {isMobile}=useSize()
  if(isMobile)return null
  const downloadImage = () => {
    const element = document.getElementById(elementId);
    if (element) {
      console.log("downloading image with element and id", element, elementId);
      // Add text to the element
      const textElement = document.createElement("pre");
      textElement.style = `
        position: absolute;
        bottom: 4px;
        left: 4px;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 2px 5px;
        font-size: 10px;
        font-family: Arial, sans-serif;
        color: #333;
        border-radius: 3px;
      `;
      textElement.textContent = text;
      element.appendChild(textElement);

      // Capture and download the image
      html2canvas(element).then((canvas) => {
        const link = document.createElement("a");
        link.download = fileName;
        link.href = canvas.toDataURL("image/png");
        link.click();

        // Remove the text element after capturing
        element.removeChild(textElement);
      });
    }
  };

  return (
    <Button
      type=""
      style={{ float: makeAbsolute && "right" }}
      icon={<DownloadOutlined />}
      onClick={downloadImage}
    ></Button>
  );
}
