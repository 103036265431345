import { message } from "antd";
import { selectCompanies } from "../redux/features/appSlice";
import store from "../redux/store";
import { findForm } from "../static/formRoutes";
import { separateObject } from "./wodash";

export function getUserToken() {
  return store.getState().user?.doc?.token;
}

export function findCompany(id) {
  const companies = selectCompanies(store.getState());
  if (!companies) return { title: "Loading" };
  const company = companies.find((e) => e.id === id);
  if (company) return company;
  else return { title: "Loading Company..." };
}

export function findSite(id) {
  const sites = store.getState().app.sites;
  if (!sites) return { title: "Loading" };
  const site = sites.find((e) => e.id === id);
  if (site) return site;
  else return { title: "Loading Site...", err: true };
}

export function openSites({ editableSiteFilter = false } = {}) {
  let sites = store?.getState()?.app?.sites?.filter((site) => !site?.closed);
  if (editableSiteFilter) return sites?.filter((site) => userCanAddData(site));
  return sites;
}

export function firstSite({ editableSiteFilter = false } = {}) {
  let site = openSites({ editableSiteFilter })[0];
  return site;
}
export function firstCompany() {
  let company = selectCompanies(store.getState())[0];
  //
  return company;
}

export function findSitesByCompanyId(id, returnCompleteObject = false) {
  let sites = openSites();
  if (!sites) return [];
  return sites.filter((e) => e.companyId === id);
}

export function findUsersByCompanyId(id) {
  return store.getState().app.users.filter((e) => e.companyId === id);
}
export function findAdminsOfSite(_) {
  try {
    let users = findUsersByCompanyId(_.companyId);
    users = users.filter((user) => user.admin_of_sites?.includes(_.id));
    return users;
  } catch (err) {
    return [];
  }
}

export function findNotAdminsOfSite(_) {
  try {
    const a = findUsersByCompanyId(_.companyId);
    const b = findAdminsOfSite(_);
    return a.filter(function (item) {
      return b.indexOf(item) === -1;
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function findAdminsOfCompany(_) {
  let users = findUsersByCompanyId(_.id);
  users = users.filter((user) => user.admin_of_companies && user.admin_of_companies?.includes(_.id));
  return users;
}

export function findNotAdminsOfCompany(_) {
  const a = findUsersByCompanyId(_.id);
  const b = findAdminsOfCompany(_);
  return a.filter(function (item) {
    return b.indexOf(item) === -1;
  });
}

export const designations = {
  super_admin: "Super Admin",
  group_admin: "Group Admin",
  company_admin: "Company Admin",
  site_admin: "Site Admin",
  site_editor: "Site Editor",
  site_viewer: "Site Viewer",
  consultant: "Consultant",
  affiliate: "Affiliate",
};

export const admin_designations = [designations.super_admin, designations.consultant, designations.affiliate];

export function getDesignation(_, else_return_company_name = true) {
  //returns designation of a user
  //
  if (!_) return "";
  if (_.super_admin) {
    return "Super Admin"; // except super admin
  }
  if (_.af_id) {
    return designations.affiliate;
  } else if (_.groupAdmin) {
    return designations.group_admin;
  } else if (_.consultant) {
    return designations.consultant;
  } else if (_.admin_of_companies?.length > 0) {
    return designations.company_admin;
  } else if (_.admin_of_sites?.length > 0) {
    return designations.site_admin;
  } else if (_.editor_of_sites?.length > 0) {
    return designations.site_editor;
  } else if (_.viewer_of_sites?.length > 0) {
    return designations.site_viewer;
  }
  return else_return_company_name ? findCompany(_.companyId).title : "";
}

export function getCurrentUserDesignation() {
  return getDesignation(store.getState().user.doc);
}

export function isSuperAdmin() {
  return getCurrentUserDesignation() === designations.super_admin;
}

export function isConsultant() {
  return getCurrentUserDesignation() === designations.consultant;
}

export function canAddSite() {
  return getCurrentUserDesignation() === "Company Admin" || getCurrentUserDesignation() === "Super Admin" || getCurrentUserDesignation() === designations.affiliate;
}

export function canAddCompany() {
  return [designations.super_admin, designations.affiliate].includes(getCurrentUserDesignation());
}

export function alltags() {
  const tags = new Set();
  openSites().forEach((site) => {
    site.tags && site.tags.forEach((tag) => tags.add(tag));
  });
  return [...tags];
}

export function filterResultsBySite(siteId) {
  let results = store.getState().app.results.filter((result) => result.siteId === siteId);
  return results;
}
export function filterResultsByName(name) {
  let results = store.getState().app.results.filter((result) => result.name === name);
  return results;
}

export function findResult(id, seperateOptions) {
  const result = store.getState().app.results?.find((_) => _.id === id);
  //if seperateOptions then send two things
  if (result && seperateOptions) {
    return separateObject(result, [
      "scaletag",
      "scale_scaleBy",
      "scale_numBase",
      "scale_details",
      "scale_base",
      "scale_base_other_value",
      "scale_metrics",
      "spread_metrics",
      "newghgtag",
      "spreadtag",
      "spreadPosition",
      "spreadMonths",
      "newsupplierfactortag",
      "newsupplierfactor_value",
      "newsupplierfactor_country",
    ]);
  } else {
    return result;
  }
}

export function findDataTags(companies = findCompanies()) {
  try {
    let tags = [];
    companies.forEach((c) => {
      if (c.resulttags) tags = [...tags, ...c.resulttags];
    });
    return tags;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function getUserSiteRole(site, user = store.getState().user.doc) {
  // gets user role for a site, undefined if not present
  if (user.super_admin) {
    return designations.super_admin;
  }
  if (user.af_id) {
    return designations.affiliate;
  } else if (user.consultant) {
    return designations.consultant;
  } else if (user.groupAdmin) {
    return designations.group_admin;
  } else if (user.admin_of_companies?.includes(site.companyId)) {
    return designations.company_admin;
  } else if (user.admin_of_sites?.includes(site.id)) {
    return designations.site_admin;
  } else if (user.editor_of_sites?.includes(site.id)) {
    return designations.site_editor;
  } else if (user.viewer_of_sites?.includes(site.id)) {
    return designations.site_viewer;
  }
  return undefined;
}

export function userCanEditSite(site, user = store.getState().user.doc) {
  return [designations.super_admin, designations.site_admin, designations.company_admin, designations.group_admin, designations.affiliate]?.includes(getUserSiteRole(site, user));
}

export function userIsCompanyAdminOrAbove(site, user = store.getState().user.doc) {
  return [designations.super_admin, designations.company_admin, designations.group_admin, designations.affiliate]?.includes(getCurrentUserDesignation());
}

export function userCanAddData(site, user = store.getState().user.doc) {
  return [designations.super_admin, designations.site_admin, designations.company_admin, designations.site_editor, designations.group_admin, designations.affiliate]?.includes(
    getUserSiteRole(site, user)
  );
}

export const findSitesInCompanies = (arr) => {
  // to be used in analysis tabs, we will give either company id or site id, if company id given it will break it down to its sites
  return arr.reduce((acc, id) => {
    // Check if site exists, if not, find sites by company id and add their ids
    return findSite(id).err ? [...acc, ...findSitesByCompanyId(id).map((x) => x.id)] : [...acc, id];
  }, []);
};
export const findFormTexts = (formname, type, filter = false, filterType = "s") => {
  try {
    const texts = store.getState().config[type][formname].texts;
    //
    if (filter) return texts.filter((x) => x[filterType]);
    return texts;
  } catch (err) {
    const fields = findForm(formname).fields.map((x) => ({
      name: x,
      title: x,
    }));
    return [...fields, { name: "result", title: "result" }];
  }
};

export const findFormInstructions = (formname, prop = "forms") => {
  try {
    return store.getState().config[prop][formname]["ins"];
  } catch (err) {
    return undefined;
  }
};

export const findFormText = (formname, name, type = "title", prop = "forms", returnNameOnErr = true) => {
  /*type: title or description */
  try {
    //
    if (name === "comment") return "Comment";
    if (name === "cost") return "Cost";
    if (name === "datalink") return "Link";
    if (name === "resulttag") return "Data Tag";
    const formtexts = store.getState().config[prop][formname].texts;
    const text = formtexts.find((e) => e.name === name)[type];
    return text;
  } catch (err) {
    if (returnNameOnErr) return name;
    else return undefined;
  }
};

export const sortForm = (inputsArray, formName) => {
  const formtexts = store.getState().config["forms"][formName].texts;
  const newInputsArr = [];
  for (let text of formtexts) {
    if (text.s) newInputsArr.push(inputsArray.find((e) => e.key === text.name));
  }
  //
  return newInputsArr;
};

export const findProps = (type = "reviewpages") => {
  return store.getState().config[type].texts;
};

export const findUserById = (id) => {
  return store.getState().app.users.find((u) => u.id === id);
};
export const findUserByEmail = (email) => {
  return store.getState().app.users.find((u) => u.email === email);
};

export const findCompanies = (shouldHaveSite = true) => {
  const companies = [];
  openSites({ editableSiteFilter: true }).forEach((site) => (!shouldHaveSite || !companies?.includes(site.companyId)) && companies.push(site.companyId));
  return companies.map(findCompany);
};

export const getUpdatesProp = (prop) => {
  try {
    return store.getState().config.updates[prop];
  } catch (err) {
    return undefined;
  }
};

export const matchLocalUpdateProp = (prop) => {
  const localVal = localStorage.getItem("t_" + prop);

  return getUpdatesProp(prop) === localVal && localVal !== undefined;
};
export const setLocalUpdateProp = (prop) => {
  localStorage.setItem("t_" + prop, getUpdatesProp(prop));
};
export const getLocalUpdateProp = (prop) => {
  return localStorage.getItem("t_" + prop);
};

export const getGroupDisplayData = (group_raw_data) => {
  const group_display_data = JSON.parse(JSON.stringify(group_raw_data));
  if (group_display_data) {
    try {
      group_display_data.companies = group_display_data.companies.map((id) => findCompany(id)?.title);
      group_display_data.admins = group_display_data.admins.map((id) => {
        const user = findUserById(id);
        //
        return user ? `${user?.firstName} ${user?.lastName}` : "Unknown User";
      });
    } catch (err) {
      return { status: "loading..." };
    }
  }
  delete group_display_data.admins;
  delete group_display_data.id;

  return group_display_data;
};

export const getAllCompanyData = (companyId) => {
  try {
    const company = findCompany(companyId);
    const sites = findSitesByCompanyId(companyId, true);
    const users = findUsersByCompanyId(companyId);
    const results = [];
    sites.forEach((site) => {
      results.push(...filterResultsBySite(site.id));
    });
    return {
      company,
      sites,
      users,
      results,
    };
  } catch (err) {
    console.log(err);
    message.error("ERROR GETTING DATA");
    return {};
  }
};
