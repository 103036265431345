import {
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  LineOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Modal,
  Space,
  Table,
  Typography,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TableOptions } from ".";
import { T1 } from "../../appconfig/texts";
import Loading from "../../components/loading";
import Select1 from "../../components/select/select1";
import { totalEmissions } from "../../components/text/resultEmissions";
import { bulkDeleteResults } from "../../firebase/data/delete";
import { catcher } from "../../firebase/util";
import { AirportsDistance, findAirportCode } from "../../helper/airport";
import {
  findCompany,
  findFormText,
  findFormTexts,
  findSite,
  findUserById,
  firstCompany,
} from "../../helper/attribute";
import { isSustraxAnnual, planValue } from "../../helper/plans";
import flattenArray, {
  downloadXLSX,
  fixDecimals,
  transformKeys,
} from "../../helper/wodash";
import useSize from "../../hooks/useSize";
import { selectResults, selectTheme } from "../../redux/features/appSlice";
import store from "../../redux/store";
import Combined_table_obj, {
  mergeArrays,
} from "../../static/combinedtable.static";
import { findForm } from "../../static/formRoutes";
import { getGHGTitle } from "../../static/ghg.static";
import { extractYear } from "../form/helper/getter";
import { getResultDates } from "./resultsCalcs";
import DateInput from "../../components/input/date";
import { convertToNumbers } from "../../helper/number";
import { updateUserClicks } from "../../firebase/user/update";
const tablePair = (arr, formname, data) => {
  return arr
    .filter((key) => data[key] !== undefined && data[key] !== null)
    .map((key) => findFormText(formname, key) + ": " + data[key])
    .join(", \n");
};

// report is used in cbf report download
const dataObj = (type = "Location") => [
  {
    title: "Fuel",
    name: "fuel",
    selector: function (data) {
      const details1 = tablePair(["type"], "fuel", data);
      return {
        details1,
        report: `${fixDecimals(data.amount, 2, true)} ${data.uom} of ${
          data.type
        }`,
      };
    },
  },
  [
    {
      title: "Electricity",
      name: "electricity",
      selector: (data) => {
        const obj =
          type === "Location"
            ? { wtt: data.wtt, result: data.result }
            : { wtt: data.marketbased_wtt, result: data.marketbased_result };
        const country = findSite(data.siteId).country;
        return {
          details1: "Country: " + country,
          details2: tablePair(["supplier_factors"], "electricity", data),
          uom: "kWh",
          report:
            `${fixDecimals(data.amount, 2, true)} kWh of electricity` +
            (type === "Market"
              ? ` at ${data.supplier_factors} gCO₂e/kWh`
              : ` based on ${country}'s grid emissions`),
          ...obj,
        };
      },
    },
    {
      title: "Electricity T&D",
      name: "electricity",
      selector: (data) => {
        const obj =
          type === "Location"
            ? { wtt: data.wtt_tnd, result: data.tnd }
            : { wtt: data.marketbased_wtt_tnd, result: data.marketbased_tnd };
        return {
          details1: "Country: " + findSite(data.siteId).country,
          details2: tablePair(["supplier_factors"], "electricity", data),
          uom: "kWh",
          ghg: data.tndghg,
          ...obj,
          kwh: 0,
        };
      },
    },
  ],
  [
    {
      title: "Heat and Steam",
      name: "heatandsteam",
      selector: (data) => {
        const obj = { wtt: data.wtt, result: data.result };
        return {
          details1: "Type: " + data.q1,
          uom: "kWh",
          ...obj,
          kwh: data?.amount,
        };
      },
    },
    {
      title: "Heat and Steam T&D",
      name: "heatandsteam",
      selector: (data) => {
        if (data.q1 === "Onsite") return { result: "deleteme", wtt: null }; // this row will be deleted, quick fix for now
        const obj = { wtt: data.wtt_tnd, result: data.tnd };
        return {
          details1: "Type: " + data.q1,
          uom: "kWh",
          ...obj,
          kwh: 0,
        };
      },
    },
  ],
  {
    title: "Cars",
    name: "cars",
    selector: (data) => {
      const details1 = data.form,
        details2 = data.type;
      let report;
      if (data.q1 === "Yes") {
        report = `${fixDecimals(data.amount, 2, true)} ${
          data.uom
        } of ${details2} in a ${details1} vehicle`;
      } else {
        report = `${fixDecimals(data.distance, 2, true)} ${
          data.uom
        } in a ${details1} ${details2}`;
      }
      return {
        details1: "Type of vehicle: " + details1,
        details2:
          (data.q1 === "No"
            ? "Type of vehicle (by size): "
            : "Type of fuel: ") + details2,
        amount: data.q1 === "Yes" ? data.amount : data.distance,
        report,
      };
    },
  },
  {
    title: "Freight",
    name: "freighting",
    selector: (data) => {
      const name = "freighting";
      return {
        details2: tablePair(["ownership", "streamtype"], name, data),
        details1: tablePair(
          ["category", "type", "columntext", "level4"],
          name,
          data
        ),
        amount2: data.distance,
        uom2: data.uom,
        amount: data.mass,
        uom: data.mass_unit,
        report: `${data.distance} ${data.uom} - ${data.category} - ${data.type}`,
      };
    },
  },
  {
    title: "Waste",
    name: "waste",
    selector: (data) => {
      const name = "waste";
      return {
        details1: tablePair(["category", "type"], name, data),
        details2: tablePair(["columntext"], name, data),
        report: `${data.amount} ${data.uom} of ${data.type} (${data.columntext})`,
      };
    },
  },
  {
    title: "Bulk materials",
    name: "bulk_materials",
    selector: (data) => {
      const name = "bulk_materials";
      return {
        details1: tablePair(["category", "type"], name, data),
        details2: tablePair(["columntext"], name, data),
      };
    },
  },
  {
    title: "Flights",
    name: "flight",
    selector: (data) => {
      const name = "flight";
      let distance = data.distance;
      if (!distance) {
        distance =
          AirportsDistance({
            ...data,
            destination_airport: data.via_airport,
          }) +
          AirportsDistance({
            ...data,
            departure_airport: data.via_airport,
          });
        distance = fixDecimals(distance);
      }
      return {
        details1: tablePair(
          [
            "departure_airport",
            "via_airport",
            "destination_airport",
            "return_ticket",
          ],
          name,
          data
        ),
        details2: tablePair(["flight_type", "flight_class"], name, data),
        amount: distance,
        amount2: data.passengers,
        uom: "Km",
        uom2: "Passengers",
        report: `${data.passengers} ${data.flight_class} ${
          data.return_ticket === "Yes" ? "return flight" : "one-way flight"
        } ${
          data.departure_airport
            ? `from ${findAirportCode(
                data.departure_airport
              )} to ${findAirportCode(data.destination_airport)} ${
                data.via_airport
                  ? ", via " + findAirportCode(data.via_airport)
                  : ""
              }`
            : ""
        } each travelling ${fixDecimals(
          (distance / data.passengers) * (data.return_ticket === "Yes" ? 2 : 1),
          2,
          true
        )} km`,
      };
    },
  },
  {
    title: "Public transport",
    name: "other_travels",
    selector: (data) => {
      const name = "other_travels";
      return {
        details1: tablePair(["category"], name, data),
        details2: tablePair(["type"], name, data),
        amount: data.distance,
        amount2: data.passengers,
        uom2: "Passengers",
        report: `${fixDecimals(data.amount, 2, true)} ${data.uom?.replace(
          "passenger.",
          ""
        )} travelled by ${data.category}`,
      };
    },
  },
  {
    title: "Commuting",
    name: "commuting",
    selector: (data) => {
      const name = "commuting";
      let uom = isSustraxAnnual() ? "Distance (Total)" : "Distance (monthly)";
      uom += " - " + data.uom;
      uom = uom.replaceAll("passenger.", "");

      let amount = data.q1.includes("Monthly")
        ? data.amount
        : data.amount2 * data.days * 2;

      let report = `${amount} (Total) ${data.uom} - ${data.category} - ${data.type}`;
      return {
        details1: tablePair(["category", "type"], name, data),
        details2: tablePair(["q1"], name, data)?.replace("Monthly", "Annual"),
        amount,
        uom,
        uom2: data.q1.includes("Monthly") ? "-" : data.days + " days",
        amount2: "",
        report,
      };
    },
  },
  {
    title: "Hotel Stays",
    name: "hotel",
    selector: (data) => {
      const name = "hotel";
      return {
        details1: tablePair(["country"], name, data),
        amount: data.guests,
        uom: "Number of Guest Nights",
      };
    },
  },
  {
    title: "Refrigerants",
    name: "refrigerator",
    selector: (data) => {
      const name = "refrigerator";
      return {
        details1: tablePair(["type"], name, data),
        report: `${fixDecimals(data.amount, 2, true)} ${data.uom} of ${
          data.type
        } topped up`,
        uom: "kg",
      };
    },
  },
  [
    {
      title: "Water",
      name: "water",
      selector: (data) => {
        return {
          details1: tablePair(["waste%"], "water", data),
          ghg: data.supply_ghg,
          result: data.supply_result,
          report: `${data.amount} ${data.uom} of water, with ${data["waste%"]}% treated as wastewater`,
        };
      },
    },
    {
      title: "Wastewater",
      name: "water",
      with_prev: true,
      selector: (data) => {
        return {
          ghg: data.waste_ghg,
          amount: fixDecimals((data.amount * data["waste%"]) / 100),
          result: data.waste_result,
        };
      },
    },
  ],
  {
    title: "Paper",
    name: "paper",
    selector: (data) => {
      const name = "paper";
      return {
        details1: tablePair(["columntext"], name, data),
        details2: tablePair(["size", "gsm"], name, data),
        amount2: data.weight,
        uom2: "kg",
      };
    },
  },
  {
    title: "Home Workers",
    name: "home_workers",
    selector: (data) => {
      const name = "home_workers";
      const details1 =
        data.occupancy === "Single" ? "Single-occupancy" : "Multi-occupancy";
      const details2 = tablePair(
        ["hours_per_day", "days_per_week", "weeks_per_year"],
        name,
        data
      );
      const amount = data.workers;
      return {
        details1,
        details2,
        amount,
        uom: "Number of Workers",
        report: `${fixDecimals(amount, 2, true)} ${details1} homeworkers`,
      };
    },
  },
  {
    title: "Spend",
    name: "spending",
    selector: (data) => {
      const name = "spending";
      let db = "";
      if (data["format"].includes("NAICS")) {
        db = "NAICS";
      } else {
        db = data["sic_uk"];
      }
      return {
        details1: "Database: " + db,
        details2: tablePair(["product"], name, data),
        amount: data.spend,
        uom: data.currency.split("-")?.[0],
      };
    },
  },
  [
    {
      title: "Product Embodied",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_emb_result,
          ghg: data.emb_ghg,
        };
      },
    },
    {
      title: "Product Transport",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_dist_result,
          ghg: data.dist_ghg,
        };
      },
    },
    {
      title: "Product Manufacturing",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_manf_result,
          ghg: data.manf_ghg,
        };
      },
    },
    {
      title: "Product Distribution",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_dist_cust_result,
          ghg: data.dist_cust_ghg,
        };
      },
    },
    {
      title: "Product Usage",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_use_result,
          ghg: data.use_ghg,
        };
      },
    },
    {
      title: "Product Disposal",
      name: "product",
      selector: (data) => {
        const name = "product";
        return {
          amount: data.units,
          details1: tablePair(["product_name"], name, data),
          result: data.em_waste_result,
          ghg: data.waste_ghg,
        };
      },
    },
  ],
  {
    title: "Custom",
    name: "additional",
  },
];

const chartSumProps = {
  electricity: [
    { title: "Location", props: ["result", "tnd", "wtt", "wtt_tnd"] },
    {
      title: "Market",
      props: [
        "marketbased_result",
        "marketbased_tnd",
        "marketbased_wtt",
        "marketbased_wtt_tnd",
      ],
    },
  ],
};

export const getDetails = (formName, data, type = "Location") => {
  const form = flattenArray(dataObj(type)).find((o) => o.name === formName);
  if (!form) {
    // message.error("Form not found");
  } else {
    const obj = form.selector ? form.selector(data) : {};

    let datatag = data.resulttag;
    if (datatag) {
      datatag = `{${datatag}}`;
    } else {
      datatag = "";
    }

    if (obj.report) return { ...obj, report: `${obj.report} ${datatag}` };
    else return { ...obj, report: " " };
  }
};
const { isExtraSmall } = useSize;
var index = 1;
let ids = new Set();
const CombinedTable = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  let resultsAll = useSelector(selectResults);

  // let results = useMemo(() => morphResults(resultsAll), [resultsAll]);
  const [data, setData] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const theme_obj = useSelector(selectTheme);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Location");
  const [years, setYears] = useState([2019, 2020, 2021, 2022, 2023, 2024]);
  const [filters, setFilters] = useState({ year: false });

  //----Columns
  console.log("combined table rendered", store.getState().app.results);
  let columns = [];
  let combined_table_config = Combined_table_obj(data || []);
  try {
    columns = combined_table_config
      // .filter((obj) => !["options"].includes(obj.key))
      .map((col) => {
        if (col.key)
          return {
            dataIndex: col.key,
            ...col,
          };
        else
          return {
            ...col,
          };
      });
  } catch (err) {
    //
  }
  useEffect(() => {
    // if (data) {
    //   console.log("Using old data");
    //   return;
    // }

    const getProcessedResult = (result, selector, Title) => {
      const newResult = {};
      const selectedData = selector ? selector(result) : {};

      combined_table_config.forEach(({ key, render }) => {
        let value =
          selectedData[key] ??
          result[key] ??
          (key === "wtt" || key === "result" ? 0 : "-");

        if (key === "siteId") value = findSite(result["siteId"]).title;
        if (key === "options") {
          value = (
            <Space style={{ fontSize: "18px" }}>
              <TableOptions result={result} source="combined_data_table" />
            </Space>
          );
        }
        if (key === "companyId")
          value = findCompany(findSite(result["siteId"])?.companyId).title;
        if (key === "ghg" || key === "ghgwtt") value = getGHGTitle(value);
        if (key === "name") value = Title;
        if (key === "userName") {
          const user = findUserById(result["userId"]);
          value = `${user?.firstName || ""} ${user?.lastName || ""}`;
        }
        if (key === "userEmail") value = findUserById(result["userId"])?.email;

        newResult[key] = value;
      });

      newResult["index"] = result.id;
      newResult["id"] = result.id;

      return newResult;
    };

    const processFormData = ({ name, selector, Title, results }) =>
      results
        .filter((result) => result.name === name)
        .map((result) => getProcessedResult(result, selector, Title));

    if (resultsAll) {
      resultsAll = resultsAll.map((result) => ({
        ...result,
        ...getResultDates(result),
      }));

      let data = [];
      let ids = new Set();
      const obj = dataObj(type);

      obj.forEach((entry) => {
        if (!Array.isArray(entry)) entry = [entry];
        const resultArrays = entry.map(({ name, selector, title }) =>
          processFormData({
            name,
            selector: selector || null,
            Title: title,
            results: resultsAll,
          })
        );

        data.push(...mergeArrays(resultArrays));
      });

      data = data
        .filter((row) => typeof row?.result === "number")
        .map((row, index) => {
          ids.add(row["index"]);
          return { ...row, index: ids.size };
        });

      setData(data);
    }
  }, [resultsAll, type]);
  const handleExport = () => {
    updateUserClicks("combined_export");
    const createDataForForm = (name) => {
      // this is just for individual sheets.
      const results = store
        .getState()
        .app.results.filter((result) => result.name === name);
      const fields = findFormTexts(name, "tables", true, "s");
      const idxArr = [...ids];
      const datax = results.map((result, index) => {
        console.log(idxArr, result, data);
        const newResult = {
          "No.": index + 1,
          "Ref.": data.find((r) => r.id === result.id).index,
          Company: findCompany(findSite(result?.siteId)?.companyId)?.title,
          Site: findSite(result.siteId).title,
          "Added By (Email)": findUserById(result["userId"])?.email,
          "Added By (Name)": `${
            findUserById(result["userId"])?.firstName || ""
          } ${findUserById(result["userId"])?.lastName || ""}`,
          "Fiscal Year": extractYear(result, true) - 1,
        };
        fields.forEach(
          (field) => (newResult[field.title] = result[field.name])
        );
        newResult["Comment"] = result["comment"];
        newResult["Data Link"] = result["dataLink"];
        return newResult;
      });
      return datax;
    };
    const formnames = [...new Set(resultsAll.map((result) => result.name))];
    const moresheets = formnames.map((name) => {
      return { name: findForm(name)?.title, data: createDataForForm(name) };
    });

    // console.log(
    //   transformKeys(data, (key) => {
    //     return combined_table_config.find((obj) => obj.key === key)?.title;
    //   }),
    //   null,
    //   combined_table_config.map((x) => x.title)
    // );
    const toExport = transformKeys(finalProcess(data), (key) => {
      return combined_table_config.find((obj) => obj.key === key)?.title;
    });
    toExport.forEach((obj) => {
      delete obj[undefined];
      delete obj["Options"];
    });
    console.log(toExport);
    downloadXLSX(
      `Combined Export ${firstCompany()?.title}`,
      convertToNumbers(toExport),
      null,
      combined_table_config.map((x) => x.title),
      convertToNumbers(moresheets)
    );
  };
  const handleDeleteRows = () => {
    Modal.confirm({
      title: `Delete ${selectedRowKeys?.length} data 
      ${selectedRowKeys?.length === 1 ? "entry" : "entries"}`,
      type: "warning",
      onOk: async () => {
        await catcher(
          async () => {
            await bulkDeleteResults(selectedRowKeys);
            setSelectedRowKeys(undefined);
          },
          { setLoading }
        );
      },
    });
  };

  const dateFilter = (
    <>
      {filters.year && (
        <div style={{ display: "flex", gap: 10 }}>
          <Select1
            title={<T1 pos={"analysis.options.year"} />}
            options={[...years, "custom"]}
            value={filters.year}
            setValue={(year) =>
              setFilters({
                ...filters,
                year,
                // startdate: moment([filters.year, 0, 1]).format(timeFormat),
                // enddate: moment([filters.year, 11, 31]).format(timeFormat),
              })
            }
            minWidthOption="100px"
            minWidth={"100px"}
          ></Select1>
          {filters.year === "custom" && (
            <>
              <DateInput
                title={<T1 pos={"analysis.options.startDate"} />}
                maxWidthElem={"200px"}
                width={"300px"}
                setValue={(startdate) => {
                  setFilters({ ...filters, startdate });
                }}
                value={filters.startdate}
              />
              <LineOutlined />
              <DateInput
                title={<T1 pos={"analysis.options.endDate"} />}
                maxWidthElem={"200px"}
                width={"300px"}
                setValue={(enddate) => {
                  setFilters({ ...filters, enddate });
                }}
                value={filters.enddate}
              />
            </>
          )}
        </div>
      )}
    </>
  );

  const finalProcess = (data) => {
    return data.map((row) => {
      return {
        ...row,
        overall_total: row["wtt"] + row["result"],
      };
    });
  };

  if (!data) return <Loading></Loading>;

  return (
    <ConfigProvider
      theme={{
        algorithm: theme_obj?.isDarkMode
          ? [theme.compactAlgorithm, theme.darkAlgorithm]
          : theme.compactAlgorithm,
      }}
    >
      <div
        style={{
          display: "flex",

          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: isExtraSmall ? "10px" : "",
        }}
      >
        <Typography.Title level={3}>
          <T1 pos={"combined.title"} />{" "}
        </Typography.Title>
        {planValue("canExportCombinedResults") ? (
          <Button
            style={{ marginTop: "10px" }}
            type="primary"
            className="button-primary-1"
            onClick={handleExport}
          >
            <T1 pos={"combined.export"} /> <DownloadOutlined />
          </Button>
        ) : (
          ""
        )}
      </div>
      {dateFilter}
      {(isSustraxAnnual() || true) && (
        <Select1
          title="Result type"
          value={type}
          setValue={setType}
          options={["Location", "Market"]}
        ></Select1>
      )}
      {selectedRowKeys?.length ? (
        <Button onClick={handleDeleteRows} style={{ color: "red" }}>
          Delete {selectedRowKeys?.length} data{" "}
          {selectedRowKeys?.length === 1 ? "entry" : "entries"}
          <DeleteOutlined />
        </Button>
      ) : (
        ""
      )}
      <Table
        pagination={{
          position: ["topRight"],
          defaultPageSize: 10,
          position: ["topRight"],
          showSizeChanger: true,
          style: { position: "relative", zIndex: "1000" },
        }}
        title={() => {
          return (
            <Typography.Text
              style={{
                padding: 10,
                display: "flex",
                gap: 10,
              }}
            >
              <b>
                <InfoCircleOutlined />
              </b>
              {totalEmissions(data || [])}
            </Typography.Text>
          );
        }}
        bordered
        scroll={{ x: 2000, y: 500 }}
        dataSource={finalProcess(data)}
        columns={columns}
        rowSelection={
          true && {
            type: "",
            onChange: (selectedRowKeys, selectedRows) => {
              const ids = selectedRows.map((r) => r.id);
              setSelectedRowKeys(
                data.filter((row) => ids.includes(row?.id)).map((r) => r?.id)
              );
            },
          }
        }
        rowKey={(e) => JSON.stringify(e)}
      />
    </ConfigProvider>
  );
};

export default CombinedTable;
