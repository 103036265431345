import { Button, Divider, message, Typography, Input, DatePicker, Space, Table, Modal, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { catcher } from "../../../firebase/util";
import axios from "axios";
import { useAtom } from "jotai";
import { consultantAtom, formNamesAtom, reportDataAtom, tableDataSourceAtom } from "../atom/atoms";
import { downloadFileFromStorage } from "../../../firebase/storage/download";
import { getCurrentDate, getCurrentYear } from "../../../helper/time";
import { calculateTotals } from "../../extras/testing/other/banner-carbondi";
import store from "../../../redux/store";
import { getAnalysis } from "../../extras/testing/workers/year";
import { designations, findCompanies, getCurrentUserDesignation } from "../../../helper/attribute";
import { fixDecimals, getUniqueProps } from "../../../helper/wodash";
import { saveAs } from "file-saver";
import "./style.css";
import CbfReportDownload from "../../extras/testing/CbfReportDownload";
import mergeAndDownloadPDF from "./helper";
import { findForm, getForms } from "../../../static/formRoutes";
import CommentsTable from "./CommentsTable";
import { useSelector } from "react-redux";
import { selectResults } from "../../../redux/features/appSlice";
import Loading from "../../../components/loading";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { updateUserClicks } from "../../../firebase/user/update";

function convertListToColumns(list) {
  return list.map((item) => {
    return {
      Data: item.data,
      "Source of data": item.source || "",
      "Verifier comments": item.verifier || "",
    };
  });
}

const fieldConfig = {
  date: { type: "text", info: "The date of the report (DD/MM/YYYY format)" },
  firstName: { type: "text", info: "First name of user." },
  marketBasedEmission: { type: "text", info: "Market based emission value" },
  locationBasedEmission: { type: "text", info: "Location based emission value" },
  companyName: { type: "text", info: "Name of the company" },
  startDate: { type: "text", info: "Follow DD/MM/YYYY format" },
  endDate: { type: "text", info: "Follow DD/MM/YYYY format" },
  accuracyComments: { type: "textarea", info: "Comments on the accuracy of the report" },
  recommendations: { type: "textarea", info: "Write recommendations separated by a new line." },
  consultant_signature: { type: "text", info: "Consultant's signature URL (signature to be used in report)" },
  consultant_details: { type: "textarea", info: "Details about the consultant" },
};

const Verification = () => {
  const results = useSelector(selectResults);
  const [consultant, setConsultant] = useAtom(consultantAtom);
  const [formNames, setFormNames] = useState(formNamesAtom);
  const [tableDataSource, setTableDataSource] = useState(tableDataSourceAtom);
  const [reportData, setReportData] = useAtom(reportDataAtom);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (consultant) {
      getReportData(consultant).then(setReportData);
    }
  }, [consultant]);

  useEffect(() => {
    if (results) {
      const uniqueFormNames = getUniqueProps(results, "name");
      const formNames = uniqueFormNames.map((name) => findForm(name)?.title);
      setFormNames(formNames);
      setTableDataSource(formNames.map((name) => ({ data: name, key: name })));
    }
  }, [results]);

  if (!results) return <Loading />;

  const getReportData = async (consultant) => {
    const options = {
      wtt: "Included",
      type: "Location",
      reporting: "All",
      sites: getCurrentUserDesignation() !== designations.group_admin ? [findCompanies()[0]?.id] : findCompanies()?.map((c) => c.id),
    };

    const locationAnalysis = getAnalysis(store.getState().app.results, options);
    const marketAnalysis = getAnalysis(store.getState().app.results, {
      ...options,
      type: "Market",
    });

    const locationTotals = calculateTotals(locationAnalysis.dataSource, getCurrentYear(), true);
    const marketTotals = calculateTotals(marketAnalysis.dataSource, getCurrentYear(), true);

    return {
      date: getCurrentDate(),
      firstName: store.getState().user.doc.firstName,
      marketBasedEmission: fixDecimals(marketTotals),
      locationBasedEmission: fixDecimals(locationTotals),
      companyName: findCompanies()?.[0]?.title,
      startDate: null,
      endDate: null,
      accuracyComments: "",
      recommendations: "",
      consultant_signature: await downloadFileFromStorage(consultant.signature, "sig.png", true),
      consultant_details: consultant.details_for_template,
      consultantEmail: consultant.email,
      formNames,
      formCommentsTable: [],
    };
  };

  const handleGetConsultant = async () => {
    await catcher(
      async () => {
        const email = prompt("Email");
        const password = prompt("Password");
        if (!email || !password) return message.error("Email or password cannot be empty");

        const { data } = await axios.post(`${process.env.REACT_APP_BE}/api/user/login/consultant`, { username: email, password });
        if (!data.consultant) return message.error("Error performing action");
        setConsultant(data);
      },
      { loading_msg: "Starting secure consultant session..." }
    );
  };

  const handleDownloadReport = async () => {
    catcher(
      async () => {
        await updateUserClicks("download_pdf_report");
        const inputFile = await downloadFileFromStorage("assets/verification_template.docx", "template.docx", true);
        const dataToSend = {
          inputFile,
          context: {
            ...reportData,
            recommendations: reportData.recommendations.split("\n"),
            formCommentsTable: convertListToColumns(tableDataSource),
          },
        };
        console.log(dataToSend);
        const result = await axios.post("https://testing-wasilislam.pythonanywhere.com/docx/template", dataToSend, { responseType: "blob" });
        saveAs(result.data, `${findCompanies()?.[0]?.title} Verification Report.docx`);
      },
      { loading_msg: "Generating file, please wait" }
    );
  };

  const handleChange = (key, value) => {
    setReportData((prevData) => ({ ...prevData, [key]: value }));
  };

  const renderField = (key, fieldConfigItem) => {
    const label = key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
    const commonProps = { key, className: "report-data-field" };
    const inputProps = {
      value: reportData[key],
      onChange: (e) => handleChange(key, e.target.value),
    };
    const labelElement = (
      <label className="report-data-label" style={{ fontWeight: "bold" }}>
        {label}:
      </label>
    );
    const infoElement = (
      <Typography.Text className="report-data-info" type="secondary">
        {fieldConfigItem.info}
      </Typography.Text>
    );

    switch (fieldConfigItem.type) {
      case "text":
        return (
          <div {...commonProps}>
            <div className="flexer">
              {labelElement}
              <Input {...inputProps} status={(!reportData[key]?.length || !reportData[key] === "string") && "warning"} className="report-data-input" style={{ borderColor: "#40a9ff" }} />
            </div>
            {infoElement}
          </div>
        );
      case "textarea":
        return (
          <div {...commonProps}>
            <div className="flexer">
              {labelElement}
              <Input.TextArea {...inputProps} className="report-data-textarea" style={{ borderColor: "#40a9ff" }} />
            </div>
            {infoElement}
          </div>
        );
      case "date":
        return (
          <div {...commonProps}>
            {labelElement}
            <DatePicker value={reportData[key]} onChange={(date) => handleChange(key, date)} className="report-data-datepicker" style={{ borderColor: "#40a9ff" }} />
            {infoElement}
          </div>
        );
      default:
        return null;
    }
  };

  const renderReportDataFields = () => Object.keys(reportData).map((key) => fieldConfig[key] && renderField(key, fieldConfig[key]));

  const renderReportData = () => (
    <>
      <Divider style={{ fontWeight: "bold", fontSize: "20px" }}>Analysis Verification Report</Divider>
      <div className="report-data-fields">
        {renderReportDataFields()}
        <Button type="primary" onClick={showModal} style={{ backgroundColor: "#1890ff", fontWeight: "bold" }}>
          Show Comments Table
        </Button>
      </div>
      <Space>
        <Tag>
          <h3>TODO:</h3>
        </Tag>
        First,{" "}
        <Button type="primary" onClick={handleDownloadReport} style={{ backgroundColor: "#1890ff", fontWeight: "bold" }}>
          Download Report
        </Button>
        <Typography.Text>, check the downloaded pdf for correct data and formatting. Convert it to pdf and </Typography.Text>
        <CbfReportDownload
          renderThisInstead={
            <Button type="primary" style={{ fontWeight: "bold" }}>
              Upload here
            </Button>
          }
          callFunctionInsteadOfDownload={mergeAndDownloadPDF}
          year={2024}
          results={store.getState().app.results}
        />{" "}
        for merging with analysis report.
      </Space>
    </>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!consultant)
    return (
      <Button onClick={handleGetConsultant} style={{ backgroundColor: "#1890ff", color: "white", fontWeight: "bold" }}>
        Start
      </Button>
    );

  return (
    <div>
      <Typography.Title
        level={3}
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#1890ff",
          fontWeight: "bold",
        }}
      >
        Hi {consultant.firstName} {consultant.lastName}!
        <Button onClick={() => setConsultant(null)} style={{ backgroundColor: "#ff4d4f", color: "white", fontWeight: "bold" }}>
          Logout session
        </Button>
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: "16px", fontWeight: "bold" }}>You can use this page to perform user actions and download reports.</Typography.Paragraph>
      <Typography.Paragraph style={{ fontSize: "16px" }}>
        <Typography.Title level={5} style={{ fontWeight: "bold" }}>
          Personal Details:
        </Typography.Title>
        <pre>{consultant.details_for_template}</pre>
        <Button type="link" onClick={() => downloadFileFromStorage(consultant.signature, "your_signature.png")} style={{ fontWeight: "bold" }}>
          Download signature
        </Button>
      </Typography.Paragraph>

      <Modal
        title="Comments Table"
        open={isModalVisible}
        onOk={handleOk}
        width={"90%"}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} style={{ backgroundColor: "#1890ff", fontWeight: "bold" }}>
            OK
          </Button>,
        ]}
      >
        <CommentsTable dataSource={tableDataSource} setDataSource={setTableDataSource} />
      </Modal>
      {renderReportData()}
    </div>
  );
};

export default Verification;
