import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Spin, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LuWand } from "react-icons/lu";
import ErrorBoundary from "../../../../ErrorBoundary";
import Loading from "../../../../components/loading";
import TickButton from "../../../../components/overreact/button/TickButton";
import { getAppInfo } from "../../../../firebase/appinfo/create";
import { catcher } from "../../../../firebase/util";
import {
  findCompanies,
  firstCompany,
  getUserToken,
} from "../../../../helper/attribute";
import { copyToClipboard } from "../../../../helper/copypaste";
import { getCurrentDate, getCurrentYear } from "../../../../helper/time";
import useSize from "../../../../hooks/useSize";
import { setCompanies } from "../../../../redux/features/appSlice";
import store from "../../../../redux/store";
import { getForms } from "../../../../static/formRoutes";
import Boxed from "./boxed";
import { configDataSourceForDownload } from "./exportbutton";
const getResponsesText = (company) => {
  const maxResponses = {
    free: 5,
    basic: 50,
    premium: 100,
  };
  if (maxResponses[company?.plan])
    return (
      "(" +
      (company?.gpt_resp_count ?? 0) +
      " of " +
      maxResponses[company?.plan] +
      " uses)"
    );
};

function formatToPrompt(data) {
  try {
    console.log(data);
    const str = "";
    const finalObj = {};
    const titles = getForms().map((form) => form.title);

    for (let i = 0; i < data.length; i++) {
      const title = data[i]?.name;
      if (!title) {
        continue;
      } else {
        for (let j = 0; j < titles.length; j++) {
          console.log(titles[j], title);
          if (title.includes(titles[j])) {
            finalObj[titles[j]] = finalObj[titles[j]]
              ? finalObj[titles[j]] + data[i][getCurrentYear()]
              : data[i][getCurrentYear()];
            break;
          }
        }
      }
    }
    return JSON.stringify(finalObj);
  } catch (err) {
    console.log(err);
    throw err;
  }
}
const processTextToArray = (text) => {
  text = text?.replaceAll("**", "");
  if (!text) return [];

  return text.split("\n").map((line, index) => {
    if (line.startsWith("#")) {
      const content = line.substring(1).trim(); // Extract content without '#'
      return {
        type: "header",
        content: content,
        styles: {
          textDecoration: "underline",
          color: "darkgreen",
          marginBottom: "10px",
          cursor: "pointer",
        },
      };
    } else {
      // For lines not starting with '#', return the text content
      return {
        type: "text",
        content: line,
      };
    }
  });
};
const TextReader = ({ text, onClickIndex, loading }) => {
  // Utilize the processTextToArray function to convert text to an array of objects
  const processedText = processTextToArray(text);

  // Function to render text items
  const renderTextItems = () => {
    return processedText
      .filter((item) => item.type === "text")
      .map((item, index) => <p key={index}>{item.content}</p>);
  };

  function addIndex(str, ind) {
    // Use a regex to check if the string starts with a number followed by a period
    if (!/^\d+\./.test(str)) {
      str = `${ind + 1}. ` + str; // Prepend '1.' by default if it doesn't match
    }
    return str;
  }

  // Function to render header items
  const renderHeaderItems = () => {
    return processedText
      .filter((item) => item.type === "header")
      .map((item, index) => (
        <div
          key={index}
          style={item.styles}
          onClick={() => onClickIndex(index)}
        >
          {addIndex(item.content, index)}
          {/* Optionally include buttons or other elements here */}
        </div>
      ));
  };

  return (
    <div style={{ fontSize: "16px" }}>
      {renderTextItems()}
      {renderHeaderItems()}
    </div>
  );
};

export default function GPTRecommendations({
  data: dataSource,
  cols,
  options,
}) {
  const [rec, setRec] = useState();
  console.log(rec);
  const { isExtraSmall } = useSize();
  const [loading, setLoading] = useState(false);
  const [followUpPrompts, setFollowUpPrompts] = useState([]);
  useEffect(() => {
    if (rec) {
      setFollowUpPrompts(
        processTextToArray(rec)
          .filter((item) => item.type === "header")
          ?.map((item) => item.content)
      );
    }
  }, [rec]);
  const company = firstCompany();
  const handleGenRecommendations = (followUpIndex = -1) => {
    catcher(
      async () => {
        const dataToSend = formatToPrompt(
          configDataSourceForDownload(dataSource, cols, options)
        );
        let aiObj = await getAppInfo("ai");
        let packet = {
          userData: dataToSend,
          token: getUserToken(),
          prompt: aiObj?.prompt,
        };
        if (followUpIndex !== -1) {
          packet.prompt = aiObj?.followUpPrompt;
          const followUpPrompt =
            processTextToArray(rec).filter((item) => item.type === "header")[
              followUpIndex
            ]?.content +
            " " +
            packet.prompt;
          if (!followUpPrompt)
            return message.error("no follow up prompt found!");
          packet = {
            ...packet,
            followUpData: rec,
            followUpPrompt,
          };
        }
        const { data } = await axios.post(
          process.env.REACT_APP_BE + "/api/ai/generateRecommendations",
          packet
        );
        store.dispatch(
          setCompanies(
            findCompanies()?.map((c) =>
              company.id !== c.id
                ? c
                : { ...c, gpt_resp_count: (c?.gpt_resp_count ?? 0) + 1 }
            )
          )
        );
        console.log("Data", data);
        const recommendations = data.choices[0].message.content;
        setRec(recommendations);

        localStorage.setItem(
          "ai-recommendations",
          recommendations + `\n\n (generated on ${getCurrentDate()})`
        );
      },
      { setLoading }
    );
  };
  useEffect(() => {
    if (localStorage.getItem("ai-recommendations")) {
      setRec(localStorage.getItem("ai-recommendations"));
    }
  }, []);
  const resp_count = company?.gpt_resp_count || 0;
  const handleCopyResponse = () => {
    copyToClipboard(rec, "Copied response to clipboard");
  };
  const gen_btn = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        type="primary"
        size={isExtraSmall ?? "small"}
        style={{
          fontSize: isExtraSmall ? "15px" : "16px",
          height: "fit-content",
          displayL: "flex",
          marginBottom: "20px",
        }}
        onClick={() => handleGenRecommendations()}
        loading={loading}
      >
        Generate Recommendations
      </Button>
      <div style={{ alignSelf: "flex-end" }}>{getResponsesText(company)}</div>
    </div>
  );
  const info = (
    <div>
      <InfoCircleOutlined style={{ color: "darkgreen" }} /> This will use AI to
      provide recommendations for carbon reductions based on your current
      results.
      <br />
      <b>Note: </b>Your company name and details will not be provided to the AI.
    </div>
  );
  const output = (
    <div>
      <div
        style={{
          border: "1px solid darkgreen",
          borderRadius: "10px",
          padding: 10,
          marginLeft: isExtraSmall ? "" : "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Typography.Title level={5}>AI Response</Typography.Title>
          <div style={{ marginTop: 18 }}>
            <TickButton icon={<CopyOutlined />} onClick={handleCopyResponse} />
          </div>
        </div>
        <pre style={{ whiteSpace: "pre-wrap" }}>
          <ErrorBoundary>
            <Spin spinning={loading} tip={"Generating responses"}>
              <TextReader text={rec} onClickIndex={handleGenRecommendations} />
            </Spin>
          </ErrorBoundary>
        </pre>
      </div>
    </div>
  );
  let comp = (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: isExtraSmall ? "column-reverse" : "row",
        alignItems: "stretch",
        gap: 10,
      }}
    >
      <div style={{ flex: 1 }}>
        {gen_btn}
        {followUpPrompts.map((txt, index) => (
          <Button
            onClick={() => handleGenRecommendations(index)}
            style={{
              width: "100%",
              marginTop: "4px",
              borderRadius: "0px !important",
              marginBottom: index === 2 ? "20px" : "3px",
            }}
          >
            Suggested follow up prompt {index + 1}
          </Button>
        ))}
        <div style={{ fontSize: 13 }}>{info}</div>
      </div>
      <div style={{ flex: 3, height: "100%" }}>{output}</div>
    </div>
  );
  // return null;//hold off
  return (
    <Boxed
      title={
        <div>
          AI Recommendations <LuWand />{" "}
        </div>
      }
    >
      {dataSource ? comp : <Loading />}
    </Boxed>
  );
}
